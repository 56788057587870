// E:\deportesyreservas\src\pages\home\Home.jsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { requestAndSaveFCMPermission } from '../../services/fcmUtils';


const Home = () => {
    const {
        currentUser,
        nickname,
        gameLevel,
        preferredClub,
        handleNicknameUpdate,
        handleGameLevelChange,
        handlePreferredClubChange,
        loading,
    } = useAuth();
    
    const [isEditing, setIsEditing] = useState(false);
    const [newNickname, setNewNickname] = useState('');
    const navigate = useNavigate();

    // useEffect para configurar el nuevo nickname cuando cambie el valor de 'nickname'
    useEffect(() => {
        if (nickname) {
            setNewNickname(nickname);
        }
    }, [nickname]);

    // useEffect para solicitar permisos FCM y guardar token
    useEffect(() => {
        if (currentUser) {
            requestAndSaveFCMPermission(currentUser, db, nickname);
        }
    }, [currentUser, nickname]);


    // Manejar la actualización del nickname
    const handleUpdateNickname = async () => {
        if (newNickname.trim() === "") {
            alert("El nickname no puede estar vacío.");
            return;
        }

        await handleNicknameUpdate(newNickname);
        setIsEditing(false);
    };

    // Verificar que todos los campos estén llenos antes de redirigir
    const handleClubButtonClick = (clubName) => {
        if (!nickname || !gameLevel || !preferredClub) {
            alert("Por favor completa todos los campos antes de continuar.");
        } else {
            const formattedClubName = clubName.toLowerCase().replace(/\s+/g, '-');
            navigate(`/club/${formattedClubName}`);
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div className='pt-14 px-4'>
            <h2 className='text-2xl font-bold mb-6'>Hola {nickname}</h2>

            {/*

            DATOS DE DEBUG PARA VER SI LOS TOMA
            <p>Correo: {currentUser?.email}</p>
            <p>Nickname: {nickname}</p>
            <p>Nivel de juego: {gameLevel}</p>
            <p>Club preferido: {preferredClub}</p>

            {currentUser && (
                <div className='text-lg mb-4'>
                    <p>ID personalizado: {`${nickname}_${currentUser.uid}`}</p>
                </div>
            )}
            
            */}


            {!isEditing ? (
                <button
                    onClick={() => setIsEditing(true)}
                    className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300'
                >
                    Modificar Nickname
                </button>
            ) : (
                <>
                    <input
                        type="text"
                        value={newNickname}
                        onChange={(e) => setNewNickname(e.target.value)}
                        placeholder="Ingresa el nuevo nickname"
                        className='border rounded-lg px-3 py-2 mb-4 w-full text-gray-700'
                    />
                    <div className='space-x-4'>
                        <button
                            onClick={handleUpdateNickname}
                            className='bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300'
                        >
                            Guardar
                        </button>
                        <button
                            onClick={() => setIsEditing(false)}
                            className='bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300'
                        >
                            Cancelar
                        </button>
                    </div>
                </>
            )}

            {/* Selección del Nivel de Juego */}
            <div className='mt-8'>
                <label className='text-lg font-bold'>Nivel de juego:</label>
                <select
                    value={gameLevel || ''}
                    onChange={(e) => handleGameLevelChange(e.target.value)}
                    className='ml-4 border rounded-lg px-3 py-2 text-gray-700'
                >
                    <option value="">Selecciona tu nivel de juego</option>
                    <option value="Principiante">Principiante</option>
                    <option value="Intermedio">Intermedio</option>
                    <option value="Avanzado">Avanzado</option>
                    <option value="Competitivo">Competitivo</option>
                    <option value="Profesional">Profesional</option>
                </select>
            </div>

            {/* Selección del Club Preferido */}
            <div className='mt-8'>
                <label className='text-lg font-bold'>Club preferido para jugar:</label>
                <select
                    value={preferredClub || ''}
                    onChange={(e) => handlePreferredClubChange(e.target.value)}
                    className='ml-4 border rounded-lg px-3 py-2 text-gray-700'
                >
                    <option value="">Selecciona tu club preferido</option>
                    <option value="La Decanatura">La Decanatura</option>
                </select>
            </div>

            {/* Botón para ver quiénes quieren jugar en el club */}
            <div className='mt-8'>
                <button
                    onClick={() => handleClubButtonClick('La Decanatura')}
                    className='bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 transition duration-300'
                >
                    Ver quienes quieren jugar en el club La Decanatura
                </button>
            </div>
        </div>
    );
};

export default Home;
