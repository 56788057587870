// E:\deportesyreservas\src\contexts\authContext\index.jsx

import React, { useContext, useState, useEffect } from "react";
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nickname, setNickname] = useState('');
  const [gameLevel, setGameLevel] = useState('');
  const [preferredClub, setPreferredClub] = useState('');

  const db = getFirestore();
  const navigate = useNavigate(); // Añadir navegación

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setCurrentUser(user);
        setUserLoggedIn(true);

        // Cargar datos del usuario
        await loadOrCreateUserData(user);
      } else {
        // Restablecer los valores si no hay un usuario autenticado
        setCurrentUser(null);
        setUserLoggedIn(false);
        setNickname('');
        setGameLevel('');
        setPreferredClub('');
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  // Función para cargar o crear los datos del usuario en Firestore
  async function loadOrCreateUserData(user) {
    try {
      const emailNickname = user.email.split("@")[0];
      const customId = `${emailNickname}_${user.uid}`;
      const userDocRef = doc(db, "users", customId);
      const userSnap = await getDoc(userDocRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        setNickname(userData.nickname || emailNickname);
        setGameLevel(userData.gameLevel || '');
        setPreferredClub(userData.preferredClub || '');

        // Verificar si gameLevel y preferredClub están completos y redirecciona al club
        if (userData.gameLevel && userData.preferredClub) {
          const formattedClubName = userData.preferredClub.toLowerCase().replace(/\s+/g, '-');
          console.log('Redireccionando al club desde authContext:', formattedClubName);
          navigate(`/club/${formattedClubName}`);
        }
      } else {
        // Crear un nuevo documento si no existe en Firestore
        await setDoc(userDocRef, {
          nickname: emailNickname,
          gameLevel: '',
          preferredClub: '',
          email: user.email
        });
        setNickname(emailNickname);
      }
    } catch (error) {
      console.error("Error al cargar o crear los datos del usuario:", error);
    }
  }

  // Función para actualizar el nickname en Firestore
  async function handleNicknameUpdate(newNickname) {
    if (currentUser) {
      try {

        // Verificar si el nickname ya está en uso
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("nickname", "==", newNickname));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          alert("El nickname ya está en uso. Por favor elige otro.");
          return;
        }
        
        const emailNickname = currentUser.email.split("@")[0];
        const customId = `${emailNickname}_${currentUser.uid}`;
        const userDocRef = doc(db, "users", customId);

        await updateDoc(userDocRef, {
          nickname: newNickname
        });
        setNickname(newNickname);
        alert("Nickname actualizado con éxito.");
      } catch (error) {
        console.error("Error al actualizar el nickname:", error);
        alert("Hubo un error al actualizar el nickname. Intenta de nuevo.");
      }
    }
  }

  // Función para actualizar el nivel de juego en Firestore
  async function handleGameLevelChange(selectedLevel) {
    if (currentUser) {
      try {
        const emailNickname = currentUser.email.split("@")[0];
        const customId = `${emailNickname}_${currentUser.uid}`;
        const userDocRef = doc(db, "users", customId);

        await updateDoc(userDocRef, {
          gameLevel: selectedLevel
        });
        setGameLevel(selectedLevel);
      } catch (error) {
        console.error("Error al actualizar el nivel de juego:", error);
        alert("Hubo un error al actualizar el nivel de juego. Intenta de nuevo.");
      }
    }
  }

  // Función para actualizar el club preferido en Firestore
  async function handlePreferredClubChange(selectedClub) {
    if (currentUser) {
      try {
        const emailNickname = currentUser.email.split("@")[0];
        const customId = `${emailNickname}_${currentUser.uid}`;
        const userDocRef = doc(db, "users", customId);

        await updateDoc(userDocRef, {
          preferredClub: selectedClub
        });
        setPreferredClub(selectedClub);
      } catch (error) {
        console.error("Error al actualizar el club preferido:", error);
        alert("Hubo un error al actualizar el club preferido. Intenta de nuevo.");
      }
    }
  }

  // Valores que se proveen al contexto
  const value = {
    userLoggedIn,
    currentUser,
    nickname,
    gameLevel,
    preferredClub,
    handleNicknameUpdate,
    handleGameLevelChange,
    handlePreferredClubChange,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
