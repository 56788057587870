import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Importar Firestore
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDRRSH2w3NlJZyFuNJkkHMSxzRaBHBjcvM",
    authDomain: "deportesyreservas.firebaseapp.com",
    projectId: "deportesyreservas",
    storageBucket: "deportesyreservas.firebasestorage.app",
    messagingSenderId: "544958129509",
    appId: "1:544958129509:web:eff1f82640d7b7524b2d8c",
    measurementId: "G-1KJEEQRWP3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Inicializar Firestore
const messaging = getMessaging(app);

// Función para solicitar permiso de notificación
const requestNotificationPermission = async () => {
    try {
        console.log('VAPID key being used:', process.env.REACT_APP_VAPID_KEY);
        const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY
        });
        if (token) {
            console.log("Token obtenido:", token);
            return token;
        } else {
            console.warn("No se recibió un token");
            return undefined;
        }
    } catch (error) {
        console.error("Error al obtener el token de notificación:", error);
        return undefined;
    }
};
;

export { app, auth, db, messaging, requestNotificationPermission, onMessage };
