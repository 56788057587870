// register/index.jsx

import React, { useState } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from '../../../firebase/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // <-- Importar correctamente getDoc desde Firestore
import { useAuth } from '../../../contexts/authContext';

const Register = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { userLoggedIn } = useAuth();

    // Registro con email y contraseña
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            setIsRegistering(true);
    
            // Validación de correo electrónico antes de intentar registrarse
            if (!validateEmail(email)) {
                setErrorMessage("El correo electrónico no es válido. Por favor, ingresa una dirección de correo válida.");
                setIsRegistering(false);
                return;
            }

            // Verificar si las contraseñas coinciden antes de continuar
            if (password !== confirmPassword) {
                setErrorMessage("Las contraseñas no coinciden. Por favor, verifica e inténtalo de nuevo.");
                setIsRegistering(false);
                return;
            }
    
            try {
                // Crear el usuario en Firebase Authentication
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Crear el documento del usuario en Firestore con el ID personalizado
                const emailNickname = email.split("@")[0];
                const customId = `${emailNickname}_${user.uid}`;
                const userDocRef = doc(db, "users", customId);
                await setDoc(userDocRef, {
                    nickname: emailNickname,
                    gameLevel: '',
                    preferredClub: '',
                    email: email
                });

                // Redirigir al usuario una vez registrado exitosamente
                setErrorMessage('');
                navigate('/home'); 
            } catch (error) {
                setErrorMessage(error.message);
                console.error('Error while registering user:', error);
            } finally {
                setIsRegistering(false);
            }
        }
    };
    
    // Función para validar correo electrónico utilizando una expresión regular
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Inicio de sesión con Google
    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Crear el documento del usuario en Firestore con el ID personalizado si no existe
            const emailNickname = user.email.split("@")[0];
            const customId = `${emailNickname}_${user.uid}`;
            const userDocRef = doc(db, "users", customId);
            const userSnap = await getDoc(userDocRef);

            if (!userSnap.exists()) {
                await setDoc(userDocRef, {
                    nickname: emailNickname,
                    gameLevel: '',
                    preferredClub: '',
                    email: user.email
                });
            }

            // Redirigir al usuario después de un inicio de sesión exitoso
            setErrorMessage('');
            navigate('/home');
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error while signing in with Google:', error);
        }
    };

    // Asegurarse de que el componente se renderiza adecuadamente
    if (userLoggedIn) {
        return <Navigate to={'/home'} replace={true} />;
    }

    return (
        <main className="w-full h-screen flex self-center place-content-center place-items-center">
            <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                <div className="text-center mb-6">
                    <div className="mt-2">
                        <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Crear una Nueva Cuenta</h3>
                    </div>
                </div>
                <form onSubmit={onSubmit} className="space-y-4">
                    <div>
                        <label className="text-sm text-gray-600 font-bold">Correo Electrónico</label>
                        <input
                            type="email"
                            autoComplete='email'
                            required
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); setErrorMessage(''); }}
                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                        />
                    </div>
                    <div>
                        <label className="text-sm text-gray-600 font-bold">Contraseña</label>
                        <input
                            disabled={isRegistering}
                            type="password"
                            autoComplete='new-password'
                            required
                            value={password}
                            onChange={(e) => { setPassword(e.target.value); setErrorMessage(''); }}
                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                        />
                    </div>
                    <div>
                        <label className="text-sm text-gray-600 font-bold">Confirmar Contraseña</label>
                        <input
                            disabled={isRegistering}
                            type="password"
                            autoComplete='off'
                            required
                            value={confirmPassword}
                            onChange={(e) => { setConfirmPassword(e.target.value); setErrorMessage(''); }}
                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                        />
                    </div>

                    {errorMessage && (
                        <span className='text-red-600 font-bold'>{errorMessage}</span>
                    )}

                    <button
                        type="submit"
                        disabled={isRegistering}
                        className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'}`}
                    >
                        {isRegistering ? 'Registrando...' : 'Registrarse'}
                    </button>
                    <div className="text-sm text-center">
                        ¿Ya tienes una cuenta? {' '}
                        <Link to={'/login'} className="text-center text-sm hover:underline font-bold">Iniciar Sesión</Link>
                    </div>
                </form>

                {/* Botón de inicio de sesión con Google */}
                <div className='flex flex-col items-center mt-6'>
                    <button
                        onClick={handleGoogleSignIn}
                        className='w-full flex items-center justify-center gap-x-3 py-2.5 border rounded-lg text-sm font-medium hover:bg-gray-100 transition duration-300 active:bg-gray-100'>
                        <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_17_40)">
                                <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                                <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                                <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                                <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
                            </g>
                            <defs>
                                <clipPath id="clip0_17_40">
                                    <rect width="48" height="48" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Continuar con Google
                    </button>
                </div>
            </div>
        </main>
    );
}

export default Register;
