// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Importar el registro del SW

// Crear el root y renderizar la aplicación con BrowserRouter
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Registrar el Service Worker generado por Workbox (esto reemplaza la parte anterior)
serviceWorkerRegistration.register();
