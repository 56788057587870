// E:\deportesyreservas\src\components\header\index.jsx

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';
import InfoIcon from '@mui/icons-material/Info'; // Importar el ícono de información
import LogoutIcon from '@mui/icons-material/Logout'; // Importar el ícono de logout
import PersonIcon from '@mui/icons-material/Person'; // Importar el ícono de person


const Header = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();

    return (
        <nav className='flex flex-row gap-x-4 w-full z-20 fixed top-0 left-0 h-12 border-b justify-end items-center bg-gray-200 px-4'>
            {
                userLoggedIn
                    ? (
                        <>
                            <Link className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/home'}>
                                <PersonIcon className="text-blue-600" />
                            </Link>
                            <Link className='flex items-center gap-2 text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/info'}>
                                <InfoIcon className="text-blue-600" />
                            </Link>
                            <button
                                onClick={() => {
                                    doSignOut().then(() => {
                                        navigate('/login');
                                    });
                                }}
                                className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200'
                            >
                                <LogoutIcon className="text-blue-600" />
                            </button>
                        </>
                    )
                    : (
                        <>
                            <Link className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/login'}>Ingresa</Link>
                            <Link className='text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/register'}>Registra una nueva cuenta</Link>
                            <Link className='flex items-center gap-2 text-sm text-blue-600 underline hover:text-blue-800 transition duration-200' to={'/info'}>
                                <InfoIcon className="text-blue-600" />
                            </Link>
                        </>
                    )
            }
        </nav>
    );
};

export default Header;
