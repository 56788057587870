import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, doc, getDocs, addDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { useAuth } from '../../contexts/authContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { requestAndSaveFCMPermission } from '../../services/fcmUtils';

const ClubPage = () => {
    const { currentUser, nickname, gameLevel } = useAuth();
    const { clubName } = useParams(); // Obtener el nombre del club de la URL
    const db = getFirestore();
    const [selectedDate, setSelectedDate] = useState(null);
    const [timeslotDetails, setTimeslotDetails] = useState([]);

    // Definir la función para cargar los detalles de los timeslots desde Firestore usando useCallback
    const loadTimeslotDetails = useCallback(async () => {
        try {
            const clubRef = doc(db, 'clubs', clubName.toLowerCase().replace(/\s/g, '-')); // Referencia dinámica del club
            const timeslotsRef = collection(clubRef, 'timeslots');
            const querySnapshot = await getDocs(timeslotsRef);

            if (!querySnapshot.empty) {
                const details = querySnapshot.docs.map((docSnapshot) => {
                    const data = docSnapshot.data();
                    let formattedTime = 'Sin fecha';
                    if (data.time) {
                        try {
                            formattedTime = data.time.toDate().toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            });
                        } catch (error) {
                            console.error(`Error al formatear el campo 'time' del timeslot ${docSnapshot.id}:`, error);
                        }
                    }
                    return {
                        id: docSnapshot.id,
                        timestamp: data.time,
                        time: formattedTime,
                        nickname: data.nickname || 'Sin nickname',
                        gameLevel: data.gameLevel || 'Sin nivel',
                        userId: data.userId || ''
                    };
                });

                const currentTime = Timestamp.now();
                const futureTimeslots = details.filter((timeslot) => {
                    return timeslot.timestamp && timeslot.timestamp.toMillis() > currentTime.toMillis();
                });

                futureTimeslots.sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis());

                const userTimeslots = futureTimeslots.filter(
                    (timeslot) => timeslot.userId === `${nickname}_${currentUser.uid}`
                );

                const updatedTimeslots = futureTimeslots.map((timeslot) => {
                    const isMatching = userTimeslots.some(
                        (userSlot) =>
                            userSlot.timestamp &&
                            userSlot.timestamp.toMillis() === timeslot.timestamp.toMillis() &&
                            timeslot.userId !== `${nickname}_${currentUser.uid}`
                    );
                    return {
                        ...timeslot,
                        isMatching: isMatching,
                        isOwnSlot: timeslot.userId === `${nickname}_${currentUser.uid}`,
                    };
                });

                setTimeslotDetails(updatedTimeslots);
            } else {
                setTimeslotDetails([]);
            }
        } catch (error) {
            console.error("Error al cargar los timeslots:", error);
        }
    }, [db, clubName, currentUser, nickname]);


    // useEffect para cargar los timeslots al montar la página y solicitar permisos de FCM
    useEffect(() => {
        loadTimeslotDetails();
        requestAndSaveFCMPermission(currentUser, db, nickname); // Llamar la función importada
    }, [loadTimeslotDetails, currentUser, db, nickname]);


    // Manejar la eliminación del timeslot
    const handleDeleteTimeslot = async (timeslotId) => {
        try {
            const clubRef = doc(db, 'clubs', clubName.toLowerCase().replace(/\s/g, '-'));
            const timeslotRef = doc(collection(clubRef, 'timeslots'), timeslotId);
            await deleteDoc(timeslotRef);
            loadTimeslotDetails();
            alert('Timeslot eliminado con éxito.');
        } catch (error) {
            console.error('Error al eliminar el timeslot:', error);
            alert('Hubo un error al eliminar el timeslot. Intenta de nuevo.');
        }
    };

    // Manejar la selección del timeslot
    const handleAddTimeslot = async () => {
        if (!selectedDate) {
            alert("Por favor selecciona una fecha y hora antes de continuar.");
            return;
        }

        const currentTime = Timestamp.now();
        if (selectedDate <= currentTime.toDate()) {
            alert("No puedes seleccionar un horario en el pasado.");
            return;
        }

        const timestamp = Timestamp.fromDate(selectedDate);
        const exists = timeslotDetails.some(
            (timeslot) => timeslot.timestamp && timeslot.timestamp.toMillis() === timestamp.toMillis() && timeslot.userId === `${nickname}_${currentUser.uid}`
        );

        if (exists) {
            alert("Ya has seleccionado este horario. Por favor selecciona otro diferente.");
            return;
        }

        try {
            const clubRef = doc(db, 'clubs', clubName.toLowerCase().replace(/\s/g, '-'));
            const timeslotsRef = collection(clubRef, 'timeslots');
            await addDoc(timeslotsRef, {
                userId: `${nickname}_${currentUser.uid}`,
                time: timestamp,
                nickname: nickname,
                gameLevel: gameLevel || 'Sin nivel'
            });
            loadTimeslotDetails();
            alert("Timeslot guardado con éxito.");
        } catch (error) {
            console.error("Error al añadir el timeslot:", error);
            alert("Hubo un error al añadir el timeslot. Intenta de nuevo.");
        }
    };

    return (
        <div className='pt-14 px-4'>
            <h2 className='text-2xl font-bold mb-6'>Jugadores disponibles en {clubName}</h2>
            {timeslotDetails.length === 0 ? (
                <p>No hay timeslots disponibles para el club {clubName}.</p>
            ) : (
                <ul>
                    {timeslotDetails.map((timeslot) => (
                        <li
                            key={timeslot.id}
                            className={`mb-4 p-2 rounded-lg ${
                                timeslot.isMatching && !timeslot.isOwnSlot
                                    ? 'bg-green-200 text-green-900 font-bold'
                                    : ''
                            }`}
                        >
                            {timeslot.time} - {timeslot.nickname} ({timeslot.gameLevel})
                            {timeslot.isMatching && !timeslot.isOwnSlot && (
                                <span className='ml-4'>✔️</span>
                            )}
                            {timeslot.userId === `${nickname}_${currentUser.uid}` && (
                                <button
                                    onClick={() => handleDeleteTimeslot(timeslot.id)}
                                    className='ml-4 text-red-500 font-bold'
                                >
                                    🗑️
                                </button>
                            )}
                        </li>                    
                    ))}
                </ul>
            )}
            <div className='mt-8'>
                <label className='text-lg font-bold'>Quiero jugar a esta hora:</label>
                <div className='mt-4'>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        showTimeSelect
                        timeIntervals={30}
                        dateFormat="Pp"
                        className='border rounded-lg px-3 py-2 text-gray-700 w-80'
                        placeholderText='Selecciona una fecha y hora'
                    />
                </div>
                <button
                    onClick={handleAddTimeslot}
                    className='mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300'
                >
                    Guardar horario
                </button>
            </div>
        </div>
    );
};

export default ClubPage;
