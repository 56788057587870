// src/pages/info/InfoPage.jsx

import React from 'react';

const InfoPage = () => {
    return (
        <div className='pt-14 px-4'>
            <h1 className='text-2xl font-bold mb-6'>Información del Proyecto</h1>
            <p className='mb-4'>Una idea original de Jair Vela</p>
            <p className='mb-4'>Desarrollo Javier Martínez</p>
            <p className='mb-4'>Si tienes alguna duda o sugerencia, puedes contactarnos a través de: info@deportesyreservas.com</p>
        </div>
    );
};

export default InfoPage;
