// E:\deportesyreservas\src\services\fcmUtils.js

import { getMessaging, getToken } from "firebase/messaging";
import { doc, setDoc, arrayUnion } from "firebase/firestore";

export const requestAndSaveFCMPermission = async (currentUser, db, nickname) => {
    if (!currentUser) {
        return;
    }

    try {
        const messaging = getMessaging();
        const vapidKey = process.env.REACT_APP_VAPID_KEY;
        console.log('Requesting notification permission with VAPID key:', vapidKey);

        const token = await getToken(messaging, { vapidKey });

        if (token) {
            const customUserId = `${nickname}_${currentUser.uid}`;
            const userDoc = doc(db, "users", customUserId);
            console.log('Saving token to Firestore:', token);

            await setDoc(userDoc, {
                fcmTokens: arrayUnion(token)
            }, { merge: true });

            console.log("Token guardado en Firestore");
        } else {
            console.warn('No token received from requestNotificationPermission');
        }
    } catch (error) {
        console.error('Error requesting notification permission:', error);
    }
};
